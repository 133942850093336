import React from 'react'
import styled, { css } from 'react-emotion'
import { Four, One, Row, Three, Two } from '../components/Grid'
import HalfSizeImage from '../components/HalfSizeImage'
import Image from '../components/Image'
import Button from '../components/Button'
import { FONT_SMALL } from '../constants'
import SEO from '../components/SEO'
import ContactForm from '../components/ContactForm'
import { prefillMessage } from '../utils'
import {
  H2,
  H4,
  Section,
  SEOH1,
  SmallText,
} from '../components/Style'
import Layout from '../components/Layout'
import Link from '../components/Link'

export default () => (
  <Layout>
    <SEO
      path="/training"
      title="React and Angular training by 500Tech"
      description="Level up your front-end development team. Professional Angular and React training, taught by industry leading experts."
      ld={breadcrumbs}
    />

    <SEOH1>Courses and workshops</SEOH1>

    <Row>
      <Three>
        <p>
          All our courses and training are built and taught by our engineers.
          The materials are based on our real world experience of building
          dozens of different applications and consulting to companies in
          diverse sectors.
        </p>

        <br />
        <Row style={{ marginBottom: 0 }}>
          <Two>
            <div className="smaller">
              <H4>Practical experience</H4>
              <p>
                All our teachers have over a decade of hands-on experience and
                are able to answer advanced questions beyond the teaching
                materials.
              </p>
            </div>
          </Two>

          <Two>
            <div className="smaller">
              <H4>Teaching experience</H4>
              <p>
                Over the past half decade, we have taught new technologies and
                skills to over a thousand developers.
              </p>
            </div>
          </Two>
        </Row>
      </Three>
    </Row>

    <Section>
      <Row>
        <Four>
          <p>Some companies that we've taught</p>
          <Image className={DesktopImage} path="training/clients.png" />
          <Image className={MobileImage} path="training/clients-mobile.png" />
        </Four>
      </Row>
    </Section>

    <Row>
      <Two>
        <Image className={DesktopImage} path="training/shay-1.jpg" />
      </Two>
      <Two>
        <Image path="training/nir-1.jpg" />
      </Two>
    </Row>

    <Section>
      <Row>
        <One>
          <CourseTitle>
            <div>
              <HalfSizeImage path="training/tech-react@2x.png" />
              <HalfSizeImage path="training/tech-redux@2x.png" />
            </div>
            React and Redux course
          </CourseTitle>
          <div>3 days</div>
        </One>

        <Two>
          <p>
            Learn how to use React and Redux to create next generation web
            applications using examples and a large amount of hands-on coding.
            The course tries to provide a deep understanding of the underlying
            React concepts and overview of how they should be used to write
            complex, large scale applications.
          </p>
          <p>
            After the course, students will be able to join existing React
            projects or start developing new projects and features.
          </p>
          <Link to="/courses/react-redux">View syllabus and details</Link>
        </Two>

        <One>
          <SmallText>
            <H4>Modules:</H4>
            <ul>
              <li>Tooling</li>
              <li>Intro to Redux</li>
              <li>Routing</li>
              <li>Intro to React</li>
              <li>Redux and React</li>
              <li>Advanced topics</li>
              <li>Components</li>
              <li>Middleware</li>
            </ul>
          </SmallText>
        </One>
      </Row>
    </Section>

    <Section>
      <Row>
        <One>
          <CourseTitle>
            <div>
              <HalfSizeImage path="training/tech-angular@2x.png" />
            </div>
            Angular course
          </CourseTitle>
          <div>4 days</div>
        </One>

        <Two>
          <p>
            In this course we will learn how to use Angular to create next
            generation web applications. The course covers all the practical
            aspects of developing with Angular, and all the platform's main
            modules and concepts.
          </p>
          <p>
            This course was built following the multitude of Angular projects we
            worked and consulted on. It is always kept up-to-date both
            technically and with new architectural approaches (and gotchas).
          </p>
          <Link to="/courses/angular">View syllabus and details</Link>
        </Two>

        <One>
          <SmallText>
            <H4>Modules:</H4>
            <ul>
              <li>Dev Environment</li>
              <li>TypeScript Primer</li>
              <li>Architecture and concepts</li>
              <li>Components Deep Dive</li>
              <li>Component Composition</li>
              <li>Providers & DI</li>
              <li>Router and forms</li>
              <li>Data Architecture</li>
            </ul>
          </SmallText>
        </One>
      </Row>
    </Section>

    <Section>
      <Row>
        <One>
          <CourseTitle>Advanced workshops</CourseTitle>
          <div>1 day</div>
        </One>
        <Two>
          We offer full-day advanced workshops for your team, to take their
          skills to the next level, for a variety of web technologies
          <Technologies>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-javascript@2x.png" />
              </TechImage>
              <TechTitle>JavaScript</TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-react@2x.png" />
              </TechImage>
              <TechTitle>
                React
              </TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-angular@2x.png" />
              </TechImage>
              <TechTitle>Angular</TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-redux@2x.png" />
              </TechImage>
              <TechTitle>Redux</TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-mobx@2x.png" />
              </TechImage>
              <TechTitle>MobX</TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-webpack@2x.png" />
              </TechImage>
              <TechTitle>Webpack</TechTitle>
            </Technology>
            <Technology>
              <TechImage style={{ marginTop: 25 }}>
                <HalfSizeImage path="training/tech-babel@2x.png" />
              </TechImage>
              <TechTitle style={{ marginTop: -25 }}>Babel</TechTitle>
            </Technology>
            <Technology>
              <TechImage>
                <HalfSizeImage path="training/tech-jest@2x.png" />
              </TechImage>
              <TechTitle>Jest</TechTitle>
            </Technology>
          </Technologies>
        </Two>
      </Row>
    </Section>

    <ContactForm />
  </Layout>
)

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/training",
      "name": "Training"
    }
  }]
}`

const CourseTitle = styled(H2)`
  margin-bottom: 0.2em;
`

const ul = styled.ul`
  font: ${FONT_SMALL};
`

const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 35px 3px 25px;
`

const Technology = styled.div`
  width: 25%;
  margin-bottom: 28px;
  margin-right: 0;

  @media only screen and (max-width: 1023px) {
    margin-right: 15px;
    width: 28%;
  }
`

const TechTitle = styled.div`
  font-size: 0.8em;
  line-height: 1.2;
`

const TechImage = styled.div`
  min-height: 90px;

  & img {
    width: 75px;
    height: auto;
  }
`

const DesktopImage = css`
  display: block;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`

const MobileImage = css`
  display: none;

  @media only screen and (max-width: 1023px) {
    display: block;
  }
`
