import React from 'react'
import ImagesContext from './ImagesContext'

export default ({ path }) => (
  <ImagesContext.Consumer>
    {allImages => (
      <img
        src={`/assets/images/${path}`}
        alt=""
        width={Math.floor(allImages[path].size.width / 2)}
        height={Math.floor(allImages[path].size.height / 2)}
      />
    )}
  </ImagesContext.Consumer>
)
